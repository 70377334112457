
import img1 from "./assets/images/img3.jpg"
import img2 from "./assets/images/img3.jpg"
import img3 from "./assets/images/img3.jpg"
import img4 from "./assets/images/img31.jpg"

export default [
    {
        fields: {
            id: "1",
            title: "How to Run a Successful Business Meeting",
            date: "24 Jan, 2020",
            author: "GargaTech IT Hub Limited",
            authorurl: "https://gargatech.com",
            button: "Read More",
            commentnum: "2",
            type: "full",
            featured: true,
            description: "Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius",
            fulldesc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore Phasellus viverra nisl vitae cursus aei uismod suspendisse saepius claritas investig.",
            images: [
                {
                    fields: {
                        file: {
                            url: img1
                        }
                    }
                },
                {
                    fields: {
                        file: {
                            url: img4
                        }
                    }
                }
            ]
        }
    },
    {
        fields: {
            id: "2",
            title: "Strategy for Norway's Pesion Fund Global",
            date: "25 Jan, 2020",
            author: "GargaTech IT Hub Limited",
            authorurl: "https://gargatech.com",
            button: "Read More",
            commentnum: "1",
            type: "full",
            featured: true,
            description: "Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius",
            fulldesc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore Phasellus viverra nisl vitae cursus aei uismod suspendisse saepius claritas investig.",
            images: [
                {
                    fields: {
                        file: {
                            url: img1
                        }
                    }
                },
                {
                    fields: {
                        file: {
                            url: img4
                        }
                    }
                }
            ]
        }
    },
    {
        fields: {
            id: "3",
            title: "We are hiring many developer to develop",
            date: "14 Fab, 2020",
            author: "GargaTech IT Hub Limited",
            authorurl: "https://gargatech.com",
            button: "Read More",
            commentnum: "4",
            type: "grid",
            featured: true,
            description: "Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius",
            fulldesc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore Phasellus viverra nisl vitae cursus aei uismod suspendisse saepius claritas investig.",
            images: [
                {
                    fields: {
                        file: {
                            url: img1
                        }
                    }
                },
                {
                    fields: {
                        file: {
                            url: img4
                        }
                    }
                }
            ]
        }
    },
    {
        fields: {
            id: "4",
            title: "Main reasons to explan fast business builder",
            date: "23 Mar, 2020",
            author: "Garga Tech IT Hub Limited",
            authorurl: "https://gargatech.com",
            button: "Read More",
            commentnum: "0",
            type: "grid",
            featured: false,
            description: "Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius",
            fulldesc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore Phasellus viverra nisl vitae cursus aei uismod suspendisse saepius claritas investig.",
            images: [
                {
                    fields: {
                        file: {
                            url: img1
                        }
                    }
                }
            ]
        }
    },
    {
        fields: {
            id: "5",
            title: "Blackpool polices hunt for David Schwimmer",
            date: "23 Mar, 2020",
            author: "Garga Tech IT Hub Limited",
            authorurl: "https://gargatech.com",
            button: "Read More",
            commentnum: "0",
            type: "grid",
            featured: false,
            description: "Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius",
            fulldesc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore Phasellus viverra nisl vitae cursus aei uismod suspendisse saepius claritas investig.",
            images: [
                {
                    fields: {
                        file: {
                            url: img2
                        }
                    }
                }
            ]
        }
    },
    {
        fields: {
            id: "6",
            title: "Business Structured are changed by Minzel",
            date: "23 Mar, 2020",
            author: "Garga Tech IT Hub Limited",
            authorurl: "https://gargatech.com",
            button: "Read More",
            commentnum: "0",
            type: "grid",
            featured: false,
            description: "Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius",
            fulldesc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore Phasellus viverra nisl vitae cursus aei uismod suspendisse saepius claritas investig.",
            images: [
                {
                    fields: {
                        file: {
                            url: img3
                        }
                    }
                }
            ]
        }
    }
]
