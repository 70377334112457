import React  from 'react'
import { Link } from "react-router-dom";
import {FiCalendar, FiChevronRight, FiFileText, FiMic, FiMonitor, FiTarget, FiUserCheck} from "react-icons/fi";
import {MdAccountBalance} from "react-icons/md";
import {AiFillSecurityScan, AiOutlineDollar, AiOutlineFileSearch, AiOutlineUsergroupAdd} from "react-icons/ai";
import {IoIosBuild, IoIosCalendar, IoIosFingerPrint, IoIosLock} from "react-icons/io";


export default function Service(props) {
    let serviceLists = [
        {
            icon: <AiOutlineDollar className="service-items-icon" />,
            title: 'Plans/Packages.',
            titleurl: '/service-details',
            description: 'We have different plans/packages available, each tailored to specific associations needs such as member registration, event management, document sharing, and more.',
            showbtn: false,
            buttontext: 'Learn More',
            buttonurl: '/service-details',
        },
        {
            icon: <MdAccountBalance className="service-items-icon" />,
            title: 'Member Management.',
            titleurl: '/service-details',
            description: 'Associations can add, manage, and update their member database efficiently. Members can create individual profiles with relevant information for better networking.',
            showbtn: false,
            buttontext: 'Learn More',
            buttonurl: '/service-details',
        },
        {
            icon: <FiMic className="service-items-icon" />,
            title: 'News & Updates.',
            titleurl: '/service-details',
            description: ' Associations can create and share news articles to keep their members updated on important developments. Highlight the flexibility of sharing news, announcements, and updates. ',
            showbtn: false,
            buttontext: 'Learn More',
            buttonurl: '/service-details',
        },
        {
            icon: <FiCalendar className="service-items-icon" />,
            title: 'Events & Meetings',
            titleurl: '/service-details',
            description: ' Associations can schedule, manage, and promote events and meetings through the platform. Sync events and meetings with your association\'s calendar for seamless planning.',
            showbtn: false,
            buttontext: 'Learn More',
            buttonurl: '/service-details',
        },
        {
            icon: <AiOutlineUsergroupAdd className="service-items-icon" />,
            title: 'Committees & Excos',
            titleurl: '/service-details',
            description: 'Create committees and executive committees (excos) to facilitate focused discussions and decision-making. Offer tools for committees and excos to communicate and coordinate effectively.',
            showbtn: false,
            buttontext: 'Learn More',
            buttonurl: '/service-details',
        },
        {
            icon: <FiFileText className="service-items-icon" />,
            title: 'Document Sharing',
            titleurl: '/service-details',
            description: 'Associations can upload and share documents securely with their members.\n Members can access documents related to events, meetings, and more from a central location.',
            showbtn: false,
            buttontext: 'Learn More',
            buttonurl: '/service-details',
        },
        {
            icon: <IoIosLock className="service-items-icon" />,
            title: 'Privacy Settings',
            titleurl: '/service-details',
            description: 'Associations can decide whether to keep their content private within their members or share it with the wider Sec Sav community.',
            showbtn: false,
            buttontext: 'Learn More',
            buttonurl: '/service-details',
        }
    ];

    serviceLists = props.isHome ? serviceLists.slice(0, 6) : serviceLists;
        return (
            <>
                {
                    serviceLists.map((item, index) => {
                        return <div className="service-items" key={index}>
                            <span className="service-items-num">{index}</span>
                            <span className="service-items-iconbox">{item.icon}</span>
                                <h3 className="service-items-title">{item.title}</h3>
                            <p className="service-items-description">{item.description}</p>
                            {item.showbtn?
                                <Link to={item.buttonurl} className="simple-button">{item.buttontext} <FiChevronRight className="icon" /></Link>
                            : ''}
                        </div>
                    })
                }
            </>
        )
}

