import React  from 'react'
import Navbar from "../components/common/Navbar";
import Breadcrumb from '../components/common/Breadcrumb';
import Footer from '../components/other/Footer';
import sectiondata from "../store/store";
import About1 from '../components/about/About1'
import SimpleTab from "../components/tabs/SimpleTab";
import {tab1} from "../resources/constants";
export default function About_Page() {
    return (
        <main className="about-page">
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="About Us." />

            {/* About 2 */}
            <About1 />

            {/* Why Choose Us */}
            <SimpleTab secTitle={tab1.secTitle} secSubtitle={tab1.secSubtitle} tabslist={tab1.items} tabsnav={tab1.tabsnav} />


            {/* Footer */}
            <Footer />
        </main>
    )
}
