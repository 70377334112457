import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BlogProvider } from './context'
import { ProductProvider } from './productcontext'
import { BrowserRouter as Router } from 'react-router-dom'
import {createRoot} from 'react-dom/client';
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
    <Router>
        <BlogProvider>
            <ProductProvider>
                <App />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </ProductProvider>
        </BlogProvider>
    </Router>
);
// ReactDOM.render(
//     <Router>
//         <BlogProvider>
//             <ProductProvider>
//                 <App />
//             </ProductProvider>
//         </BlogProvider>
//     </Router>
//     , document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
