import React  from 'react'
import Navbar from "../components/common/Navbar";
import About1 from '../components/about/About1'
import Divider from '../components/other/Divider'
import CounterUp from "../sections/CounterUp";
import HeroSlider from '../components/sliders/HeroSlider'
import SimpleTab from '../components/tabs/SimpleTab'
import Footer from '../components/other/Footer';
import sectiondata from "../store/store";
import ServiceSection1 from "../sections/services/ServiceSection1";
import herosliderbg from "../assets/images/bg1.jpg";
import herosliderbg2 from "../assets/images/bg2.jpg";
import herosliderbg3 from "../assets/images/bg3.jpg";
import {AiFillHome, AiOutlineCheckCircle, AiOutlineUsergroupAdd} from "react-icons/ai";
import CountUp from "react-countup";
import {FaHandshake} from "react-icons/fa";
import {counterUp, heroSlider, tab1} from "../resources/constants";
import Hero from '../components/sliders/Hero';

export default function Home() {



    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Hero Slider
            <HeroSlider slideItems={heroSlider} /> */}

            {/* Hero Slider */}
            <Hero />

            {/* Services */}
            <ServiceSection1 isHome={true} secTitle={sectiondata.Services.Service1.secTitle} secSubtitle={sectiondata.Services.Service1.secSubtitle} secButton={sectiondata.Services.Service1.secButton} secButtonUrl={sectiondata.Services.Service1.secButtonUrl} serviceItems={sectiondata.Services.Service1.serviceLists} />

            <Divider />

            {/* About 1*/}
            <About1 />
            <Divider />


            {/* Counter Up */}
            <CounterUp
                secTitle={counterUp.secTitle}
                secSubtitle={counterUp.secSubtitle}
                counteritems={counterUp.items}
                counterbottomtitle={counterUp.counterbottomtitle}
                counterbottombtn={counterUp.counterbottombtn}
                counterbottombtnurl={counterUp.counterbottombtnurl} />


            <Divider />

            {/* Why Choose Us */}
            <SimpleTab secTitle={tab1.secTitle} secSubtitle={tab1.secSubtitle} tabslist={tab1.items} tabsnav={tab1.tabsnav} />


            {/* Footer */}
            <Footer />
        </>
    )
}

