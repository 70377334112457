import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SectionsTitle from "../components/common/SectionsTitle";
import { Link } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";
import axios from "axios";
import { currencyConverter, serverLink } from "../resources/constants";
import SpinnerLoader from "../components/common/spinner-loader/spinner-loader";

export default function Pricing(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [priceList, setPriceList] = useState([]);
  const [tabList, setTabList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");

  const getData = async () => {
    await axios
      .get(`${serverLink}organisation/website/package/list`)
      .then((res) => {
        if (res.data.message === "success") {
          let row = [];
          let timeline = [
            ...new Set(res.data.data.map((item) => item.Timeline)),
          ];
          setTabList(timeline);
          setPriceList(res.data.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleTabChange = (index) => {
    setSelectedTab(tabList[index]);
  };

  return isLoading ? (
    <section className="pricing-wrapper">
      <div className="container">
        <SpinnerLoader />
      </div>
    </section>
  ) : (
    <section className="pricing-wrapper">
      <div className="container">
        <div className="text-center">
          <SectionsTitle
            title="Choose Your Plan."
            subtitle="Affordable Packages"
          />
        </div>
        <Tabs onSelect={(index) => handleTabChange(index)}>
          {tabList ? (
            <TabList className="tabs-nav">
              {tabList.map((item, i) => {
                return <Tab key={i}>{item}</Tab>;
              })}
            </TabList>
          ) : (
            ""
          )}
          {tabList.map((timeline, i) => {
            const filteredPriceList = priceList.filter(
              (item) => item.Timeline === timeline
            );
            return (
              <TabPanel className="tabs-content text-center" key={i}>
                <div className="pricing-plan-grid">
                  {filteredPriceList.map((plan, index) => {
                    return (
                      <div
                        key={index}
                        className={"pricing-plan-box active  mb-4"}
                        style={{ margin: "10px", marginTop: "70px" }}
                      >
                        {plan.PackageName ? (
                          <h2 className="title">{plan.PackageName}</h2>
                        ) : (
                          ""
                        )}
                        <h3 className="price">
                          {currencyConverter(plan.Amount)}
                        </h3>
                        {/* <h3 className="mo">/{plan.Timeline === 'Annual' ? 'Yearly' : 'Monthly'}</h3> */}
                        <ul className="feature-lists">
                          <li
                            style={{ padding: "5px", textAlign: "center" }}
                          >{`${plan.MaxMember} - Maximum Member`}</li>
                          <li
                            style={{ padding: "5px", textAlign: "center" }}
                          >{`${plan.MaxEmail} - Maximum Email`}</li>
                          <li
                            style={{ padding: "5px", textAlign: "center" }}
                          >{`${plan.MaxSMS} - Maximum SMS`}</li>
                          <li
                            style={{ padding: "5px", textAlign: "center" }}
                          >{`${plan.StorageSize}GB - Maximum Storage Size`}</li>
                          <li style={{ padding: "5px", textAlign: "center" }}>
                            Association Management
                          </li>
                          <li style={{ padding: "5px", textAlign: "center" }}>
                            Membership Management
                          </li>
                          <li style={{ padding: "5px", textAlign: "center" }}>
                            Mailing
                          </li>
                          <li style={{ padding: "5px", textAlign: "center" }}>
                            News & Events
                          </li>
                          <li style={{ padding: "5px", textAlign: "center" }}>
                            Meeting Management
                          </li>
                          <li style={{ padding: "5px", textAlign: "center" }}>
                            Exco & Committee Management
                          </li>
                          <li style={{ padding: "5px", textAlign: "center" }}>
                            Document Management & File Sharing
                          </li>
                          <li style={{ padding: "5px", textAlign: "center" }}>
                            Forum
                          </li>
                        </ul>
                        <Link to="/register" className="theme-button">
                          <FiShoppingCart className="icon" /> Get Started
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </section>
  );
}
