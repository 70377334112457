import React from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { FiPhoneCall } from 'react-icons/fi'
import { MdLocationOn, MdMailOutline } from 'react-icons/md'
import { projectAddress, projectEmail, projectPhone } from "../../resources/constants";

export default function CtaForm() {
    return (
        <section className="ctaform-wrapper">
            <div className="container">
                <div className="ctaform-grid">
                    <div className="ctaform-left">
                        <SectionsTitle title="Contact us" subtitle="Contact " />
                        <div className="ctaform-contact-info-grid text-center">
                            <div className="item">
                                <FiPhoneCall className="icon" />
                                <h2 className="item-title">Direct Line Numbers</h2>
                                <p>{projectPhone}</p>
                            </div>
                            <div className="item">
                                <MdMailOutline className="icon" />
                                <h2 className="item-title">Our Email</h2>
                                <p>{projectEmail}</p>
                            </div>
                            <div className="item">
                                <MdLocationOn className="icon" />
                                <h2 className="item-title">Address</h2>
                                <p>{projectAddress}</p>
                            </div>
                        </div>

                        <div className="ctaform-contact-info-grid text-center">
                            <div className="" style={{marginTop: '20px'}}>
                                <div className="ctaform-button-container">
                                    <a href={'tel:'+projectPhone} className="theme-button" style={{ marginRight: '8px' }}>Call us</a>
                                </div>
                            </div>
                            <div className="" style={{marginTop: '20px'}}>
                            <div className="ctaform-button-container">
                                    <a href={"mailto:"+projectEmail} className="theme-button" style={{ marginRight: '8px' }}>Email us</a>
                                </div>
                            </div>
                            <div className="" style={{marginTop: '20px'}}>
                            <div className="ctaform-button-container">
                            <a href={`https://www.google.com/maps?q=${encodeURIComponent(projectAddress)}`} className="theme-button" style={{ marginRight: '8px' }}>Find us</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="mapouter" style={{ marginTop: '20px', height: '400px' }}>
                    <div className="gmap_canvas">
                        <iframe width="100%" height="100%" id="gmap_canvas"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1656.5001998253238!2d7.479243538038276!3d9.078004849046366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sng!4v1692614909305!5m2!1sen!2sng"
                                frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" style={{ marginTop: '20px', height: '400px' }} />

                    </div>
                </div>
            </div>
        </section>
    )
}
