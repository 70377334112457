import {Info
} from "react-feather";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import { serverLink} from "../../../resources/constants";
import placeholder from "../../../assets/images/organization.jpg";
import {FiChevronRight, FiChevronsLeft, FiChevronsRight, FiPlus} from "react-icons/fi";
import {FaEye} from "react-icons/fa";

function NewsView(props) {
    const [newsList, setNewsList] = useState(props.data ?? []);
    const [newsBKList, setNewsBKList] = useState(props.data ?? []);


    // Pagination
    const usersPerPage = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentData = newsList.slice(indexOfFirstUser, indexOfLastUser);
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearch = (e) => {
        let filtered = newsBKList.filter((x) =>
            !x.Title || x.Title.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()))
        setNewsList(filtered)
    }
    return (
        <section className="blog-wrapper ">
            <div className="container">
                <div className="shop-widget widget_search">
                    <span className='text-dark fw-bold' style={{fontWeight: 'bold', fontSize: '16px'}}>{newsList.length} Results Found</span>
                    <form><input type="search" name="search" placeholder="Search News" onChange={handleSearch}/>
                        <button type="submit" className="theme-button">
                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24"
                                 strokeLinecap="round" strokeLinejoin="round" className="icon" height="1em"
                                 width="1em" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11" cy="11" r="8"/>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"/>
                            </svg>
                        </button></form>
                </div>
                <div className="blog-grid">
                    {
                        currentData.length > 0 ?
                        currentData.map((item, index)=> {
                            return (
                                <div className="blog" key={index}>
                                    <div className="blog-img-box">
                                        <img src={!item.ImagePath ? placeholder : `${serverLink}public/upload/news/${item.ImagePath}`} alt="News Logo" style={{
                                            maxHeight: '280px',
                                            minHeight: '280px',
                                            border: '1px solid #cccccc',
                                            borderTopLeftRadius: '8px',
                                            borderTopRightRadius: '8px',
                                        }} />
                                        <div className="blog-overlay">
                                            <Link to={`/association/news/${props.orgSlug}/${item.Slug}`}  ><FiPlus className="icon" /></Link>
                                        </div>
                                    </div>
                                    <div className="blog-content-box">
                                        <Link to={`/association/news/${props.orgSlug}/${item.Slug}`} >
                                            <h2 className="blog-title" style={{height: '40px'}}>
                                                {item.Title}
                                            </h2>
                                        </Link>
                                        <p className="blog-desc text-justify" style={{height: '60px'}}>
                                            <span className='blog-content-truncate' dangerouslySetInnerHTML={{__html: item.Description?.slice(0, 85) + (item.Description?.length > 85 ? "..." : "")}}/>
                                        </p>

                                        <div className="blog-footer" style={{borderTop: '1px solid #eeeeee', paddingTop: '8px'}}>
                                            <Link className="theme-button" to={`/association/news/${props.orgSlug}/${item.Slug}`} >More Details <FiChevronRight className="icon w-100" /></Link>
                                            <div className="blog-comment">
                                                <Link to={`/association/news/${props.orgSlug}/${item.Slug}`} >{item.ViewCount} <FaEye className="comment" /></Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                            : (
                                <div className="main-div" style={{marginBottom: '50px'}}>
                                    <div className='text-center p-5' style={{padding: '50px'}}>
                                            <h2><Info size='20' className='me-25'/> No Results Found</h2>
                                    </div>
                                </div>
                            )
                    }
                </div>
                {/* Pagination component */}
                <ul className='pagination  d-flex justify-content-center mt-3'>
                    {/* Previous button */}
                    <li className={`${currentPage === 1 ? 'disabled' : ""}`} >
                        <Link  className='page-link' onClick={() => handlePageClick(currentPage - 1)}><FiChevronsLeft /></Link>
                    </li>

                    {/* Page numbers */}
                    {Array.from({length: Math.ceil(newsList.length / usersPerPage)}).map((_, index) => (
                        currentPage === index + 1 ?
                            <li key={index}>
                                <span className='page-link current' onClick={() => handlePageClick(index + 1)}>{index + 1}</span>
                            </li>
                            :
                        <li key={index}>
                            <Link className='page-link' onClick={() => handlePageClick(index + 1)}>{index + 1}</Link>
                        </li>
                    ))}

                    {/* Next button */}
                    <li disabled={currentPage === Math.ceil(newsList.length / usersPerPage)}>
                        <Link className='page-link' onClick={() => handlePageClick(currentPage + 1)}><FiChevronsRight /></Link>
                    </li>
                </ul>
            </div>
        </section>

    )
}


export default NewsView;
