import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiChevronRight, FiPlay } from "react-icons/fi";
import about_video_img from "../../assets/images/img1.jpg";
import history_img from "../../assets/images/img2.jpg";
import history_img1 from "../../assets/images/about1.png";
import history_img3 from "../../assets/images/about3.png";
import slider from "../../assets/images/slider1.jpg";
import vision from "../../assets/images/vision-bg.jpeg";
import ModalVideo from "react-modal-video";
import "./About1.css";

export default class About1 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal2 = this.openModal2.bind(this);
  }

  openModal2() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <section className="about-st1-wrapper">
        <div className="container">
          <div className="about-st1-grid">
            <div className="about-left">
              <h1 className="about-left-title">Our Mission </h1>
              <p className="about-left-desc">
                Our mission is to offer user-friendly platform for associations
                to streamline their operations and enhance collaboration. To
                become the go-to platform for associations seeking streamlined
                management solutions.{" "}
              </p>
              <Link to="/sign-up" className="theme-button">
                Register Now <FiChevronRight className="icon" />
              </Link>
            </div>
            <div className="about-right" style={{ position: "relative" }}>
              <img className="about-right-img" src={slider} alt="About Video" />
              {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='t3tsMEhlvwM' onClose={() => this.setState({ isOpen: false })} /> */}
              <div
                style={{
                  position: "absolute",
                  height: "150px",
                  width: "200px",
                  background: "#fabc2a",
                  fontWeight: 500,
                  padding: "5px",
                  top: "40%",
                  right: "2%",
                  fontSize: "12px",
                }}
              >
                <p>
                  Sign up on Secsav and build stronger and thriving associations
                  today
                </p>
              </div>
            </div>
          </div>
          <div className="history-area">
            <div className="row">
              <div className="col-md-8 hidden-on-mobile relative-view">
                <img
                  className="history_img"
                  src={vision}
                  alt="History"
                  style={{ width: "70%" }}
                />
              </div>
              <div className="vision history-details-box ">
                <h1 className="history-details-title">
                  <strong>Our Vision</strong>
                </h1>
                <p className="history-details-desc">
                  To become the go-to platform for associations seeking
                  streamlined management solutions.{" "}
                </p>
                <Link to="/sign-up" className="theme-button">
                  Register Now <FiChevronRight className="icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
