import React  from 'react'
import { Link } from 'react-router-dom'
import { FiChevronsLeft } from 'react-icons/fi'
import sectiondata from "../../store/store";
import errorimg from "../../assets/images/404-img3.gif";

export default function ErrorContent() {
    const errorpagedata = {
        img: errorimg,
        title: 'Page Not Found.',
        content: 'The page you\'re looking for could not be found. Please double-check the URL or navigate back to safety. If you need assistance, feel free to contact us.',
        connector: 'or',
        backbtn: 'Back To Home',
        footermenu: [
            {
                title: 'Home',
                path: '/'
            },
            {
                title: 'About Us',
                path: '/about'
            },
            {
                title: 'Service',
                path: '/services'
            },
            {
                title: 'Contact',
                path: '/contact'
            },
        ]
    };
    return (
        <section className="error-content-wrapper text-center">
            <div className="error-content-width">
                {errorpagedata.img ?
                    <img src={errorpagedata.img} alt="Error" />
                : ''}
                {errorpagedata.title ?
                    <h1>{errorpagedata.title}</h1>
                : ''}
                {errorpagedata.content ?
                    <p>{errorpagedata.content}</p>
                : ''}
                {errorpagedata.connector ?
                    <div className="connector">
                        <span>{errorpagedata.connector}</span>
                    </div>
                : ''}
                {errorpagedata.backbtn ?
                    <Link className="theme-button" to="/"><FiChevronsLeft className="icon" /> {errorpagedata.backbtn}</Link>
                : ''}
            </div>
        </section>
    )
}
