import {Link} from "react-router-dom";
import {FiPhone} from "react-icons/fi";
import {FaEnvelope, FaMapMarkerAlt} from "react-icons/fa";
import React from "react";
import SocialProfile from "./SocialProfile";
import logo from "../../assets/images/roza.png";

export default function FooterLeft({ftlogo, content, phonenumber, email, address, scltitle, scllists}) {
    return (
        <div className="footer-widget footer-left">
            <Link to="/">
                <img src={ftlogo} alt="Footer Logo" width={200} height={70} />
            </Link>
            <p className="desc">
                {content}
            </p>
            {/* Social Profile */}
            <div className="social mt-5">
                <SocialProfile title={scltitle} lists={scllists} />
            </div>
        </div>
    )
}
