import { FiChevronRight, FiChevronsLeft, FiChevronsRight, FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Info } from "react-feather";
import placeholder from "../../assets/images/organization.jpg";
import {encryptData,  serverLink} from "../../resources/constants";

function OrganizationView(props) {
    const [organizationList, setOrganizationList] = useState(props.data ?? []);
    const [organizationBkList, setOrganizationBkList] = useState(props.data ?? []);

    // Pagination
    const usersPerPage = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentData = organizationList.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(organizationList.length / usersPerPage);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearch = (e) => {
        let filtered = organizationBkList.filter((x) =>
            !x.Name || x.Name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
        );
        setOrganizationList(filtered);
    };

    return (
        <section className="blog-wrapper mt-5">
            <div className="container">
                {/* Content */}
                <div className="blog-grid">
                    {currentData.length > 0 ? (
                        currentData.map((item, index) => {
                            return (
                                <div className="blog" key={index}>
                                    <div className="blog-img-box">
                                        <img
                                            src={!item.LogoPath ? placeholder : `${serverLink}public/upload/organisation/${item.LogoPath}`}
                                            alt="Organization Logo"
                                            style={{
                                                maxHeight: "280px",
                                                minHeight: "280px",
                                                border: "1px solid #cccccc",
                                                borderTopLeftRadius: "8px",
                                                borderTopRightRadius: "8px",
                                            }}
                                        />
                                        <div className="blog-overlay">
                                            <Link to={`/association/${item.OrgName}`}>
                                                <FiPlus className="icon" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="blog-content-box">
                                        <Link to={`/association/${item.OrgName}`}>
                                            <h2 className="blog-title" style={{ height: "40px" }}>
                                                {item.Name}
                                            </h2>
                                        </Link>
                                        <p className="blog-desc text-justify" style={{ height: "50px" }}>
                                            <span
                                                className="blog-content-truncate"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.Description?.slice(0, 85) + (item.Description?.length > 85 ? "..." : ""),
                                                }}
                                            />
                                        </p>

                                        <div className="blog-footer" style={{ borderTop: "1px solid #eeeeee", paddingTop: "8px" }}>
                                            <Link className="theme-button" to={`/association/${item.OrgName}`}>
                                                More Details <FiChevronRight className="icon w-100" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="text-center p-5">
                            <h2 className="p-1">
                                <Info size="19" className="me-25" /> No Results Found
                            </h2>
                        </div>
                    )}
                </div>

                {/* Pagination */}
                <ul className="pagination">
                    {/* Previous button */}
                    <li className={`${currentPage === 1 ? "disabled" : ""}`} style={{ display: "flex", alignItems: "center", justifyContent: "center", margin:0, padding:0 }}>
                        <Link className="page-link" onClick={() => handlePageClick(currentPage - 1)}>
                            <FiChevronsLeft />
                        </Link>
                    </li>

                    {/* Page numbers */}
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <li key={index}>
                            <Link
                                className={`page-link ${currentPage === index + 1 ? "current" : ""}`}
                                onClick={() => handlePageClick(index + 1)}
                            >
                                {index + 1}
                            </Link>
                        </li>
                    ))}

                    {/* Next button */}
                    <li className={currentPage === totalPages ? "disabled" : ""} style={{ alignSelf: "center" }}>
                        <Link className="page-link" onClick={() => handlePageClick(currentPage + 1)}>
                            <FiChevronsRight />
                        </Link>
                    </li>
                </ul>
            </div>
        </section>
    );
}

export default OrganizationView;
