import React from "react";
import {FiPhone} from "react-icons/fi";
import {FaEnvelope, FaMapMarkerAlt} from "react-icons/fa";

export default function FooterSchedule({title, offday, schedulelists, phonenumber, email, address}) {
    return (
        <div className="footer-widget footer-working-hours">
            <h2>{title}</h2>
            <ul className="working-hours">
                <li className="mb-3">
                    <a className="text-white" href={"tel:"+phonenumber}><span className="icon"><FiPhone /></span> {phonenumber}</a>
                </li>
                <li className="mb-3">
                    <a className="text-white" href={"mailto:"+email}><span className="icon"><FaEnvelope /></span> {email}</a>
                </li>
                <li className="mb-3">
                    <a className="text-white" href="https://maps.google.com"><span className="icon"><FaMapMarkerAlt /></span> {address}</a>
                </li>
            </ul>
            {/*<ul className="working-hours">*/}
            {/*    {schedulelists.map((item, i) => {*/}
            {/*        return (*/}
            {/*            <li key={i}><strong>{item.day}</strong> {item.time}</li>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*    {offday?*/}
            {/*        <li className="off-day">{offday} <strong>Off</strong></li>*/}
            {/*    : ''}*/}
            {/*</ul>*/}
        </div>
    )
}
