import React from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import Pricing from "../sections/Pricing";
import Footer from '../components/other/Footer'

export default function PricingPage() {
    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Our Pricing." />

            {/* Pricing Plan */}
            <div className="plain-pricing-table">
                <Pricing />
            </div>

            {/* Footer */}
            <Footer />
        </>
    )
}
