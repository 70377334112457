import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import HeaderBar from "../common/HeaderBar";
import $ from "jquery";
import logo from "../../assets/images/roza.png";
import { projectOrganisationLogin } from "../../resources/constants";

export default function Navbar() {
  const [navOpen, setnavOpen] = useState(false);
  const location = useLocation();

  /*====  side-widget-menu  =====*/
  $(document).on(
    "click",
    ".side-menu-wrap .side-menu-ul .sidenav__item",
    function (e) {
      $(".side-menu-wrap .side-menu-ul .sidenav__item").removeClass("active");
      $(this).toggleClass("active");
    }
  );
  $(document).on(
    "click",
    ".side-menu-wrap .side-menu-ul .sidenav__item.active",
    function (e) {
      $(".side-menu-wrap .side-menu-ul .sidenav__item.active").removeClass(
        "active"
      );
    }
  );

  $(window).on("scroll", function () {
    // Header Menu Fixed
    if ($(window).scrollTop() > 30) {
      $("body").addClass("header-menu-fixed");
      $(".scroll-d-block").addClass("d-block");
    } else {
      $("body").removeClass("header-menu-fixed");
      $(".scroll-d-block").removeClass("d-block");
    }
  });

  const headerMenuBar = {
    logourl: logo,
    menus: [
      {
        title: "Home",
        path: "/",
      },
      {
        title: "About Us",
        path: "/about",
      },
      {
        title: "Services",
        path: "/services",
      },
      {
        title: "Pricing",
        path: "/our-pricing",
      },
      {
        title: "Associations",
        path: "/associations",
      },
      {
        title: "Contact",
        path: "/contact",
      },
    ],
  };

  const scrollTop = async () => {
    await window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
  }, [location]);

  return (
    <>
      <HeaderBar />

      <div className="header-menu-wrapper scroll-d-block">
        <div className="header-menu-container container">
          <div className="header-menu-flex">
            <div className="header-menu-left">
              <Link to="/">
                <img src={logo} alt="Logo" width={"auto"} height={70} />
              </Link>
            </div>
            <div className="header-menu-right">
              <nav>
                <ul>
                  {headerMenuBar.menus.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link to={item.path}>
                          {item.title}{" "}
                          {item.dropdown ? (
                            <FiChevronDown className="menu-icon" />
                          ) : (
                            ""
                          )}
                        </Link>
                        {item.dropdown ? (
                          <ul className="dropdown-menu-item">
                            {item.dropdown.map((dp_item, i) => {
                              return (
                                <li key={i}>
                                  <Link to={dp_item.path}>
                                    {dp_item.title}{" "}
                                    {dp_item.dep_dropdown ? (
                                      <FiChevronRight className="icon" />
                                    ) : (
                                      ""
                                    )}
                                  </Link>
                                  {dp_item.dep_dropdown ? (
                                    <ul className="dropdown-menu-item">
                                      {dp_item.dep_dropdown.map(
                                        (dp_item_inr, i2) => {
                                          return (
                                            <li key={i2}>
                                              <Link to={dp_item_inr.path}>
                                                {dp_item_inr.title}
                                              </Link>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })}
                </ul>
              </nav>
              <div className="header-menu-right-flex">
                <div className="header-menu-cart">
                  <ul></ul>
                </div>
                <div
                  className="side-menu-open"
                  onClick={() => setnavOpen(!navOpen)}
                >
                  <span className="menu__bar"></span>
                  <span className="menu__bar"></span>
                  <span className="menu__bar"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={navOpen ? "side-nav-container active" : "side-nav-container"}
      >
        <div className="humburger-menu">
          <div
            className="humburger-menu-lines side-menu-close"
            onClick={() => setnavOpen(!navOpen)}
          />
        </div>
        <div className="side-menu-wrap">
          <ul className="side-menu-ul">
            {headerMenuBar.menus.map((item, index) => {
              return (
                <li className="sidenav__item" key={index}>
                  <Link to={item.path}>{item.title}</Link>
                  {item.dropdown ? (
                    <span className="menu-plus-icon"></span>
                  ) : (
                    ""
                  )}
                  {item.dropdown ? (
                    <ul className="side-sub-menu">
                      {item.dropdown.map((dp_item, i) => {
                        return (
                          <li key={i}>
                            <Link to={dp_item.path}>{dp_item.title}</Link>
                            {dp_item.dep_dropdown ? (
                              <ul className="dropdown-menu-item">
                                {dp_item.dep_dropdown.map((dp_item_inr, i2) => {
                                  return (
                                    <li key={i2}>
                                      <Link to={dp_item_inr.path}>
                                        {dp_item_inr.title}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
            <li
              className="sidenav__item sidenav__item2 text-center mb-2"
              style={{ marginRight: "15px" }}
            >
              <Link to={projectOrganisationLogin}>Login</Link>
            </li>
            <li
              className="sidenav__item sidenav__item2 text-center"
              style={{ marginRight: "15px" }}
            >
              <Link to="/sign-up">sign up</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
