import CryptoJS from "crypto-js";
import herosliderbg from "../assets/images/bg1.png";
import herosliderbg2 from "../assets/images/bg2.png";
import herosliderbg3 from "../assets/images/about3.png";
import vision from "../assets/images/vision.jpg";
import slider from "../assets/images/slider.jpeg";
import {
  AiFillHome,
  AiOutlineCheckCircle,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import CountUp from "react-countup";
import { FaHandshake } from "react-icons/fa";
import React from "react";

export const serverStatus = "Live";
export const serverLink =
  serverStatus === "Dev"
    ? "http://192.168.1.75:4480/"
    : "https://api.secsav.com/";
export const projectTitle = "SecSav";
export const projectCode = "ROZA_CORPORATE";
export const projectPhone = "+2348108145166";
export const projectEmail = "info@secsav.com";
export const projectAddress =
  "42 Onitsha Crescent, Garki, Behind Gimbiya Street Abuja, Nigeria";
export const projectOrganisationLogin = "https://app.secsav.com";
export const projectFacebook = `https://web.facebook.com/secsav`;
export const projectTwitter = `https://twitter.com/`;
export const projectInstagram = `https://instagram.com/secsav_app`;
export const projectYouTube = `https://www.youtube.com/channel/UCq60qjxS2e4ltxFoYlK918w`;
export function encryptData(string, val = false) {
  try {
    let secret_key =
      val === false ? "ROZA_CORPORATE" + "_ENCRYPT" : projectCode;
    let secret_iv =
      val === false ? "ROZA_CORPORATE" + "_ENCRYPT_IV" : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

    let result = decrypted.toString();
    return btoa(result);
  } catch (e) {
    console.log(e);
  }
}

export function decryptData(string, val = false) {
  try {
    let secret_key =
      val === false ? "ROZA_CORPORATE" + "_ENCRYPT" : projectCode;
    let secret_iv =
      val === false ? "ROZA_CORPORATE" + "_ENCRYPT_IV" : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    console.log(e);
  }
}

export const formatDateAndTime = (date, option) => {
  if (date !== null) {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();
    else if (option === "month_and_year")
      date_string = `${
        monthNamesShort[user_date.getMonth()]
      } - ${user_date.getFullYear()}`;

    return date_string;
  } else {
    return "--";
  }
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const dateDifference = (start_date, end_date) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(start_date);
  const secondDate = new Date(end_date);

  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};
export const currencyConverter = (amount) => {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });
  return formatter.format(amount);
};

export const numberFormat = (number) => {
  const formatter = new Intl.NumberFormat("en-NG");
  return formatter.format(number);
};

export function getRemainingDays(startDate, endDate) {
  const start = new Date(startDate.toUTCString());
  const end = new Date(endDate.toUTCString());
  const differenceInMs = end - start;
  return Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
}

export function getPercentageBetweenNumbers(value, min, max) {
  return ((value - min) / (max - min)) * 100;
}

export const randomToken = () => {
  return Math.random().toString(36).substr(2); // remove `0.`
};

export const heroSlider = [
  {
    title: "Are you tired of running your association",
    description: "We can make it better",
    button1: "Get Started",
    button2: "Learn More",
    button1url: "/register",
    button2url: "/about",
    bg: slider,
    col: "8",
  },
  // {
  //     title: '',
  //     description: '',
  //     button1: 'Let\'s Start Now',
  //     button2: 'Learn More',
  //     button1url: '/register',
  //     button2url: '/about',
  //     bg: herosliderbg2,
  //     col: '8 offset-2 text-center'
  // },
  // {
  //     title: '',
  //     description: '',
  //     button1: 'Let\'s Start Now',
  //     button2: 'Learn More',
  //     button1url: '/register',
  //     button2url: '/about',
  //     bg: herosliderbg3,
  //     col: '8'
  // }
];

export const counterUp = {
  secTitle: "Numbers Speak.",
  secSubtitle: "See What Our",
  items: [
    {
      icon: <AiFillHome className="icon" />,
      number: (
        <CountUp
          start={0}
          end={50}
          duration={8}
          separator=" "
          decimal=","
          prefix=" "
          suffix=" "
        />
      ),
      numsuffix: "+",
      title: "Associations",
    },
    {
      icon: <AiOutlineUsergroupAdd className="icon" />,
      number: (
        <CountUp
          start={0}
          end={195}
          duration={8}
          separator=" "
          decimal=","
          prefix=" "
          suffix=" "
        />
      ),
      numsuffix: "+",
      title: "Registered Members",
    },
    {
      icon: <FaHandshake className="icon" />,
      number: (
        <CountUp
          start={0}
          end={15}
          duration={8}
          separator=" "
          decimal=","
          prefix=" "
          suffix=" "
        />
      ),
      numsuffix: "+",
      title: "Committees",
    },
    {
      icon: <AiOutlineCheckCircle className="icon" />,
      number: (
        <CountUp
          start={0}
          end={30}
          duration={8}
          separator=" "
          decimal=","
          prefix=" "
          suffix=" "
        />
      ),
      numsuffix: "+",
      title: "Excos",
    },
  ],
  counterbottomtitle:
    "Do not hesitate to contact us for instant help and support",
  counterbottombtn: "Register Now",
  counterbottombtnurl: "/register",
};

export const tab1 = {
  secTitle: "Why Choose Us.",
  secSubtitle: "Our Best Features",
  tabsnav: [
    {
      title: "Tailored Plans and Packages",
    },
    {
      title: "Effortless Registration Process",
    },
    {
      title: "Comprehensive Member Management",
    },
  ],
  items: [
    {
      img: vision,
      desc: "Select from a range of plans and packages that align with your associations specific needs. Whether you are a small nonprofit or a large enterprise, we have the right solution for you.",
      button: "Get Started",
      buttonurl: "/register",
    },
    {
      img: herosliderbg2,
      desc: "Signing up with Sec Sav is simple and user-friendly. Our intuitive registration process ensures that you're up and running in no time, ready to explore all the features at your fingertips.",
      button: "Get Started",
      img_position: "img_right",
      buttonurl: "/register",
    },
    {
      img: herosliderbg,
      desc: "Efficiently manage your association's members within our platform. Register new members, update their information, and maintain a well-organized database for effective networking.",
      button: "Get Started",
      buttonurl: "/register",
    },
  ],
};
