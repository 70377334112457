import React  from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import SectionsTitle from '../components/common/SectionsTitle'
import Service from '../components/services/Services'
import Footer from '../components/other/Footer'

export default function ServicePage() {
    return (
        <div className="service-main">
            <header className="header-area">
                <Navbar />
            </header>
            <Breadcrumb title="Services." />
            <div className="h-service">
                <div className="container-fluid">
                    <div className="row text-center">
                        <SectionsTitle  title={"We Offer a user-friendly platform for associations to streamline their operations and enhance collaboration."} subtitle={"Our Dedicated Services"}  />
                    </div>
                </div>
                <div className="container">
                    <div className="services-items-grid">
                        <Service />
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    )
}
