import React from "react";
import { FiHeart } from "react-icons/fi";
import ScrollTopBtn from "../layouts/ScrollTopBtn";
import FooterLeft from "../widgets/FooterLeft";
import FooterCommonLinks from "../widgets/FooterCommonLinks";
import FooterSchedule from "../widgets/FooterSchedule";
import FooterForm from "../widgets/FooterForm";
import sectiondata from "../../store/store";
import footerLogo from "../../assets/images/roza-yellow.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import {
  projectAddress,
  projectEmail,
  projectFacebook,
  projectInstagram,
  projectPhone,
  projectTitle,
  projectYouTube,
} from "../../resources/constants";

export default function Footer() {
  const footerdata = {
    ftLeftwidget: {
      footerlogo: footerLogo,
      content:
        "A user-friendly platform for associations to streamline their operations and enhance collaboration",
      phonenumber: projectPhone,
      email: projectEmail,
      address: projectAddress,
      socials: {
        title: "Follow & Connect On.",
        lists: [
          {
            icon: <FaFacebookF />,
            url: projectFacebook,
            target: "_blank",
          },
          // {
          //     icon: <FaTwitter />,
          //     url: 'https://twitter.com'
          // },
          {
            icon: <FaInstagram />,
            url: projectInstagram,
            target: "_blank",
          },
          // {
          //     icon: <FaLinkedinIn />,
          //     url: 'https://linkedin.com',
          //     target: "_blank",
          // },
          {
            icon: <FaYoutube />,
            url: projectYouTube,
            target: "_blank",
          },
        ],
      },
    },
    ftCommonLinks: {
      title: "Useful Links",
      lists: [
        {
          title: "Services",
          url: "/services",
        },
        {
          title: "About Us",
          url: "/about",
        },
        {
          title: "Pricing",
          url: "/our-pricing",
        },
        {
          title: "Associations",
          url: "/associations",
        },

        {
          title: "Contact Us",
          url: "/contact",
        },
        {
          title: "Privacy Policy",
          url: "/privacy-policy",
        },
        {
          title: "Terms and Conditions",
          url: "/terms-and-conditions",
        },
        {
          title: "Refund Policy",
          url: "/refund-policy",
        },
      ],
    },
    ftSchedule: {
      title: "Working Hours.",
      offday: "Sat-Sun:",
      litss: [
        {
          day: "Monday:",
          time: "9am-4pm",
        },
        {
          day: "Tuesday:",
          time: "9am-4pm",
        },
        {
          day: "Wednesday:",
          time: "9am-4pm",
        },
        {
          day: "Thursday:",
          time: "9am-4pm",
        },
        {
          day: "Friday:",
          time: "9am-4pm",
        },
      ],
    },
  };
  return (
    <>
      <ScrollTopBtn />
      <footer className="footer-wrapper">
        <div className="container">
          <div className="footer-widgets-grid">
            {/* Footer Left */}
            <FooterLeft
              ftlogo={footerdata.ftLeftwidget.footerlogo}
              email={footerdata.ftLeftwidget.email}
              content={footerdata.ftLeftwidget.content}
              address={footerdata.ftLeftwidget.address}
              phonenumber={footerdata.ftLeftwidget.phonenumber}
              scltitle={footerdata.ftLeftwidget.socials.title}
              scllists={footerdata.ftLeftwidget.socials.lists}
            />

            {/* Footer Common Links */}
            <FooterCommonLinks
              title={footerdata.ftCommonLinks.title}
              lists={footerdata.ftCommonLinks.lists}
            />

            {/* Footer Schedule */}
            <FooterSchedule
              title={"Contact Us."}
              offday={footerdata.ftSchedule.offday}
              schedulelists={footerdata.ftSchedule.litss}
              address={footerdata.ftLeftwidget.address}
              email={footerdata.ftLeftwidget.email}
              phonenumber={footerdata.ftLeftwidget.phonenumber}
            />
          </div>
          <div className="copyright-wrap text-center">
            <p>
              &copy; Copyright {projectTitle}{" "}
              {new Date(Date.now()).getFullYear()}. Made with{" "}
              <FiHeart className="heartbeat" /> by{" "}
              <a href="https://gargatech.com">GargaTech.</a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
