import React, {useEffect} from "react";
import { Switch, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import About_Page from './pages/About_Page'
import OurPricing from './pages/PricingPage'
import SignUp from './pages/SignUpPage'
import Login from './pages/LoginPage'
import ForgotPassword from './pages/ForgotPassword'
import ServicePage from './pages/ServicePage'
import ServiceDetailsPage from './pages/ServiceDetailsPage'
import ContactPage from './pages/ContactPage'
import Error from './pages/Error'
import "./assets/css/style.css";
import "./App.css";
import Organization from "./components/organization/organization";
import OrganizationDetails from "./components/organization/organization-details";
import NewsDetails from "./components/organization/organisation-news/organisation-news-details";
import EventDetails from "./components/organization/organisation-event/organisation-event-details";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsAndConditions from "./pages/terms-and-conditions";
import RefundPolicy from './pages/refund-policy';

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route>
          <Route exact path="/" element={<Home/>} />
          <Route path="/about" element={<About_Page/>} />
          <Route path="/associations" element={<Organization/>} />
          <Route path="/association/:slug" element={<OrganizationDetails/>} />
          <Route path="/association/news/:slug/:slug" element={<NewsDetails/>} />
          <Route path="/association/event/:slug/:slug" element={<EventDetails/>} />
          <Route path="/our-pricing" element={<OurPricing/>} />
          <Route path="/register" element={<SignUp/>} />
          <Route path="/sign-up" element={<SignUp/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/forgot-password" element={<ForgotPassword/>} />
          <Route path="/services" element={<ServicePage/>} />
          <Route path="/service-details" element={<ServiceDetailsPage/>} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
          <Route path="/refund-policy" element={<RefundPolicy/>} />
          <Route exact path="*" element={<Error/>} />
        </Route>
      </Routes>
    </React.Fragment>
  );
}

export default App;
