import React  from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import Footer from '../components/other/Footer'
import { projectAddress, projectEmail, projectPhone } from '../resources/constants';

export default function TermsAndConditions() {
    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Terms and Conditions" />

            <section className="ctaform-wrapper" style={{marginTop: '-70px'}}>
                <div className="container">
                    <div className="ctaform-grid">
                        <div className="ctaform-left">
                            <div className="mapouter" style={{ marginTop: '20px', textAlign: 'justify' }}>
                                <h1>SECSAV DIGITAL SERVICES - Terms and Conditions</h1>
                                <p>These terms and conditions ("Terms") govern your use of the SecSav software as a
                                    service ("SecSav"), offered by SECSAV DIGITAL SERVICES, a company registered in
                                    Nigeria with the address at 42 Onitsha Crescent, Garki, Behind Gimbiya Street Abuja,
                                    Nigeria ("SECSAV DIGITAL SERVICES"). By accessing or using SecSav, you agree to be
                                    bound by these Terms. If you do not agree with any part of these Terms, you may not
                                    use SecSav.</p>

                                <h2>1. Use of SecSav</h2>
                                <p>1.1. You must be at least 18 years old to use SecSav.</p>
                                <p>1.2. You may use SecSav solely for managing association-related activities.</p>

                                <h2>2. Subscription</h2>
                                <p>2.1. By subscribing to SecSav, you agree to pay the applicable fees as communicated
                                    by SECSAV DIGITAL SERVICES.</p>
                                <p>2.2. Subscription fees are non-refundable.</p>

                                <h2>3. Privacy</h2>
                                <p>3.1. Your use of SecSav is subject to SECSAV DIGITAL SERVICES' Privacy Policy.</p>
                                <p>3.2. By using SecSav, you consent to the collection, use, and sharing of your
                                    information as described in the Privacy Policy.</p>

                                <h2>4. Intellectual Property</h2>
                                <p>4.1. SecSav and all related intellectual property rights are owned by SECSAV DIGITAL
                                    SERVICES.</p>
                                <p>4.2. You may not copy, modify, distribute, sell, or lease any part of SecSav.</p>

                                <h2>5. Limitation of Liability</h2>
                                <p>5.1. SECSAV DIGITAL SERVICES shall not be liable for any indirect, incidental,
                                    special, consequential, or punitive damages arising out of or in connection with
                                    your use of SecSav.</p>

                                <h2>6. Termination</h2>
                                <p>6.1. SECSAV DIGITAL SERVICES reserves the right to suspend or terminate your access
                                    to SecSav at any time, with or without cause.</p>
                                <p>6.2. Upon termination, your right to use SecSav will cease immediately.</p>

                                <h2>7. Contact Information</h2>
                                <p>If you have any questions about these Terms, please contact us at:</p>
                                <p>Address: {projectAddress}</p>
                                <p>Email: {projectEmail}</p>
                                <p>Phone: {projectPhone}</p>

                                <p>These Terms were last updated on May 03, 2024.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* Footer */}
            <Footer />
        </>
    )
}
