import React, {useEffect, useState} from 'react'
import Navbar from '../../common/Navbar'
import Breadcrumb from '../../common/Breadcrumb'
import Footer from '../../other/Footer'
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import { formatDate, formatDateAndTime, serverLink} from "../../../resources/constants";
import img from "../../../assets/images/img4.jpg";
import {
    FiEye,
} from "react-icons/fi";
import {FaEye, FaFacebookF, FaGooglePlusG, FaInstagram, FaTwitter} from "react-icons/fa";
import placeholder from "../../../assets/images/organization.jpg";
import OrganisationEventSidebar from "./organisation-event-sidebar";
import SpinnerLoader from "../../common/spinner-loader/spinner-loader";

export default function EventDetails() {
    const navigate = useNavigate();
    const {slug} = useParams();
    let orgSlug = '';
    let url = window.location.href;
    let split = url.split('/');
    if (split.length > 0) {
        let lastIndex = split[split.length -2]
        orgSlug = lastIndex
    }
    const [isLoading, setIsLoading] = useState(true);
    const [eventDetails, setEventDetails] = useState({});
    const [eventList, setEventList] = useState([]);


    const getData = async () => {
        let event_id = slug;
        await axios.get(`${serverLink}organisation/website/event-details/${event_id}`)
            .then((res) => {
                if (res.data.data.length > 0){
                    const record = res.data.data;
                    let details = record.filter(e=>e.Slug.toString()  === event_id.toString())
                    let other_events = record.filter(e=>e.Slug.toString()  !== event_id.toString())
                    setEventDetails(details[0]);
                    setEventList(other_events);
                    updateViewCount(details[0]?.EventID)
                }else{
                    navigate(`/associations`)
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const updateViewCount = async (id) => {
        const sendData = {event_id: id};
        await axios.patch(`${serverLink}organisation/website/event/update/view-count`, sendData)
            .then((result) => {}).catch((err) => {console.log("NETWORK ERROR");});
    };

    useEffect(() => {
        if (!slug){
            navigate("/associations")
        }
        getData()
    }, [window.location.href]);
    return (isLoading ? <SpinnerLoader/> :
            <>
                {/* Header */}
                <header className="header-area">
                    <Navbar />
                </header>

                {/* Breadcrumb */}
                <Breadcrumb title="Event Details" />

                {/* Event Details */}
                <div className="container">
                    <section className="blog-details-wrapper">
                        <div className="container">
                            <div className="blog-details-grid d-grid">
                                <div className="blog-details-left">
                                    <div className="blog-details-img-box">
                                        <img  className="img-thumbnail" src={!eventDetails.ImagePath ? placeholder : `${serverLink}public/upload/event/${eventDetails.ImagePath}`} alt="Blog Details" />
                                    </div>
                                    <div className="blog-details-contents">
                                        <span className="blog-details-meta d-flex">{formatDate(eventDetails.InsertedDate)} - Posted by - <b>{eventDetails.InsertedName} </b></span>

                                        <h2>{eventDetails?.Title}.</h2>
                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                            <Link  to={`#`}>
                                                <div className='d-flex'>
                                                    <div>
                                                        <h6 className='mb-0 primary-color'>Event Date</h6>
                                                        <small
                                                            className="text-secondary">{formatDateAndTime(eventDetails.EventDate, 'date')}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link  to={`#`}>
                                                <div className='d-flex'>
                                                    <div>
                                                        <h6 className='mb-0 primary-color'>Start Time - End Time</h6>
                                                        <small
                                                            className="text-secondary">{eventDetails.StartTime} - {eventDetails.EndTime}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <Link to={`#`}>
                                            <div className='d-flex mt-3'>
                                                {/*<Avatar color='light-primary' className='rounded me-1'*/}
                                                {/*        icon={<MapPin size={18}/>}/>*/}
                                                <div>
                                                    <h6 className='mb-0 primary-color'>Location</h6>
                                                    <small className="text-secondary">{eventDetails.Location}</small>
                                                </div>
                                            </div>
                                        </Link>
                                        <hr/>
                                        <span style={{textAlign: 'justify'}}><p  dangerouslySetInnerHTML={{__html: eventDetails?.Description}}/></span>

                                        <div className="blog-details-tags-grid d-grid">
                                            <div className="blog-details-tags-left">
                                                <FaEye/> {eventDetails.ViewCount}
                                            </div>
                                            <div className="blog-details-tags-right d-flex">
                                                <span>Share to: </span>
                                                <ul className="blog-share-links d-flex">
                                                    <li><a href="https://facebook.com"><FaFacebookF /></a></li>
                                                    <li><a href="https://twitter.com"><FaTwitter /></a></li>
                                                    <li><a href="https://instagram.com"><FaInstagram /></a></li>
                                                    <li><a href="https://plus.google.com"><FaGooglePlusG /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="blog-details-right">
                                    <OrganisationEventSidebar eventData={eventList}  orgSlug={orgSlug} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                {/* Footer */}
                <Footer />
            </>
    )
}
