import React, {useEffect, useState} from 'react'
import Breadcrumb from '../../components/common/Breadcrumb'
import Footer from '../../components/other/Footer'
import Navbar from "../common/Navbar";
import axios from "axios";
import {serverLink} from "../../resources/constants";
import SpinnerLoader from "../common/spinner-loader/spinner-loader";
import OrganizationView from "./organization-view";

 function Organization(props) {
     const [isLoading, setIsLoading] = useState(true);
     const [organisationList, setOrganisationList] = useState([]);

     const getData = async () => {
         await axios.get(`${serverLink}organisation/website/list`)
             .then((res) => {
                 if (res.data.message === "success"){
                     const record = res.data.data;
                     setOrganisationList(record);
                 }

                 setIsLoading(false);
             })
             .catch((err) => {
                 console.log("NETWORK ERROR");
             });
     };

     useEffect(() => {
         getData()
     }, []);

    return (
        isLoading ? <SpinnerLoader/> :
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Associations." />

            {/* Organization Grid */}
            <OrganizationView data={organisationList}/>
            <Footer />
        </>
    )
}

export default Organization;