import React from "react";
import {
  FiPhone,
  FiMapPin,
  FiChevronDown,
  FiChevronRight,
} from "react-icons/fi";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import flat1 from "../../assets/images/flag.jpg";
import sectiondata from "../../store/store";
import { Link } from "react-router-dom";
import flat2 from "../../assets/images/flag2.jpg";
import flat3 from "../../assets/images/flag3.jpg";
import "./HeaderBar.css";
import {
  projectAddress,
  projectEmail,
  projectFacebook,
  projectInstagram,
  projectOrganisationLogin,
  projectPhone,
  projectYouTube,
} from "../../resources/constants";

export default function HeaderBar() {
  let headerBar = {
    phoneNumber: projectPhone,
    email: projectEmail,
    location: projectAddress,
    socialLists: [
      {
        icon: <FaFacebookF />,
        url: projectFacebook,
        target: "_blank",
      },
      {
        icon: <FaInstagram />,
        url: projectInstagram,
        target: "_blank",
      },
      {
        icon: <FaYoutube />,
        url: projectYouTube,
        target: "_blank",
      },
    ],
    languages: [
      {
        name: "English",
        flag: flat1,
        url: "#",
      },
      {
        name: "USA",
        flag: flat2,
        url: "#",
      },
      {
        name: "France",
        flag: flat3,
        url: "#",
      },
    ],
    btntext: "Register Now",
    btnicon: <FiChevronRight className="icon" />,
    btnurl: "/sign-up",
  };
  return (
    <div className="header-bar-wrapper">
      <div className="container">
        <div
          className="header-bar-left"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            className="header-bar-left"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ul
              className="reduce"
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {headerBar.phoneNumber ? (
                <li className="" style={{ color: "white" }}>
                  <FiPhone className="icon" />{" "}
                  <a
                    href={"tel:" + headerBar.phoneNumber}
                    style={{ color: "white" }}
                  >
                    {headerBar.phoneNumber}
                  </a>
                </li>
              ) : (
                ""
              )}
              {headerBar.email ? (
                <li className="" style={{ color: "white" }}>
                  <FaEnvelope className="icon" />{" "}
                  <a
                    href={"mailto:" + headerBar.email}
                    style={{ color: "white" }}
                  >
                    {headerBar.email}
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
          {headerBar.socialLists ? (
            <ul
              className="header-bar-social d-flex"
              style={{ display: "flex", gap: "10px" }}
            >
              {headerBar.socialLists.map((social, i) => {
                return (
                  <li key={i}>
                    <a
                      href={social.url}
                      style={{ color: "white" }}
                      target={social.target}
                    >
                      {social.icon}
                    </a>
                  </li>
                );
              })}
            </ul>
          ) : (
            ""
          )}
        </div>
        <div className="header-bar-grid">
          <div className="header-bar-right text-right">
            <div className="header-bar-right-flex d-flex">
              {/* {
                                headerBar.socialLists ?
                                    (
                                        <ul className="header-bar-social d-flex">
                                            {headerBar.socialLists.map((social, i) => {
                                                return (
                                                    <li key={i}>
                                                        <a href={social.url}>{social.icon}</a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    )
                                : ''
                            } */}

              {headerBar.btntext ? (
                <div className="header-bar-cta-btn">
                  <Link
                    to={headerBar.btnurl}
                    className="theme-button"
                    style={{ marginRight: "8px" }}
                  >
                    {headerBar.btntext} {headerBar.btnicon}
                  </Link>
                  <Link
                    to={projectOrganisationLogin}
                    className="theme-button"
                    style={{ backgroundColor: "#f4f5e4", color: "#333333" }}
                  >
                    Login {headerBar.btnicon}
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
