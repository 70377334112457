import React from "react";
import logo from "../../assets/images/roza.png";
import { Link } from "react-router-dom";

export default function HeaderError() {
  return (
    <div className="header-error-wrapper d-flex">
      <div className="left">
        <Link to="/" className="logo">
          <img src={logo} alt="Logo" width={"auto"} height={70} />
        </Link>
      </div>
      <div className="right">
        <ul className="links d-flex">
          <li>
            <Link to="/">Back to Home</Link>
          </li>
          <li>
            <a href="https://gargatech.com">Help</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
