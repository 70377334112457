import {
    Eye, Info
} from "react-feather";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {formatDateAndTime, serverLink} from "../../../resources/constants";
import { FiChevronsLeft, FiChevronsRight} from "react-icons/fi";
import {Card, CardBody, CardTitle} from "reactstrap";

function EventView(props) {
    const [eventList, setEventList] = useState(props.data ?? []);
    const [eventBKList, setEventBKList] = useState(props.data ?? []);


    // Pagination
    const usersPerPage = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentData = eventList.slice(indexOfFirstUser, indexOfLastUser);
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearch = (e) => {
        let filtered = eventBKList.filter((x) =>
            !x.Title || x.Title.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()))
        setEventList(filtered)
    }
    const formatDateToShortName = (dateString) => {
        const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const date = new Date(dateString);
        const dayOfWeek = daysOfWeek[date.getDay()];
        return dayOfWeek;
    }

    return (
        <section className="blog-wrapper ">
            <div className="container">
                <div className="shop-widget widget_search">
                    <span className='text-dark fw-bold' style={{fontWeight: 'bold', fontSize: '16px'}}>{eventList.length} Results Found</span>
                    <form><input type="search" name="search" placeholder="Search Events" onChange={handleSearch}/>
                        <button type="submit" className="theme-button">
                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24"
                                 strokeLinecap="round" strokeLinejoin="round" className="icon" height="1em"
                                 width="1em" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11" cy="11" r="8"/>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"/>
                            </svg>
                        </button></form>
                </div>
                <div className="container">
                    <div className='row gy-2'>
                        {currentData.length > 0 ? currentData.map((item, index) => {
                            const date = new Date(item.EventDate);
                            const day = date.getDate();
                            const dayName = formatDateToShortName(item.EventDate);
                            return (
                                <div className="col-md-6 mb-2" key={index}>
                                    <Card className='card-developer-meetup' style={{backgroundColor: '#eeeeee'}}>
                                        <div className='meetup-img-wrapper rounded-top text-center' style={{
                                            backgroundImage: `url(${serverLink}public/upload/event/${item.ImagePath})`,
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            // backgroundPosition: 'center center',
                                            maxHeight: '200px',
                                            minHeight: '200px',
                                            borderTopLeftRadius: '8px',
                                            borderTopRightRadius: '8px',
                                        }}>
                                            {/*<img src={meeting} height='170'/>*/}
                                        </div>
                                        <CardBody>
                                            <div className='meetup-header d-flex align-items-center'>
                                                <div className='meetup-day'>
                                                    <h6 className='mb-0'>{dayName}</h6>
                                                    <h3 className='mb-0'>{day}</h3>
                                                </div>
                                                <div className='my-auto'>
                                                    <Link to={`/association/event/${props.orgSlug}/${item.Slug}`}>
                                                        <CardTitle tag='h4' className='mb-25 text-secondary'
                                                                   style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                            {item.Title?.slice(0, 80) + (item.Title?.length > 80 ? "..." : "")}
                                                        </CardTitle>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <Link  to={`/association/event/${props.orgSlug}/${item.Slug}`}>
                                                    <div className='d-flex'>
                                                        <div>
                                                            <h6 className='mb-0 primary-color'>Event Date</h6>
                                                            <small
                                                                className="text-secondary">{formatDateAndTime(item.EventDate, 'date')}</small>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link  to={`/association/event/${props.orgSlug}/${item.Slug}`}>
                                                    <div className='d-flex'>
                                                        <div>
                                                            <h6 className='mb-0 primary-color'>Start Time - End Time</h6>
                                                            <small
                                                                className="text-secondary">{item.StartTime} - {item.EndTime}</small>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <Link  to={`/association/event/${props.orgSlug}/${item.Slug}`}>
                                                <div className='d-flex mt-2'>
                                                    {/*<Avatar color='light-primary' className='rounded me-1'*/}
                                                    {/*        icon={<MapPin size={18}/>}/>*/}
                                                    <div>
                                                        <h6 className='mb-0 primary-color'>Location</h6>
                                                        <small className="text-secondary">{item.Location}</small>
                                                    </div>
                                                </div>
                                            </Link>
                                            <hr/>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center me-1'>
                                                    <a className='p-1' href='#' onClick={e => e.preventDefault()}>
                                                        <Eye size={21} className='text-body align-middle' />
                                                    </a>
                                                    <a href='#' onClick={e => e.preventDefault()}>
                                                        <div className='text-body align-middle'>{item.ViewCount}</div>
                                                    </a>
                                                </div>
                                                <Link className='fw-bold primary-color'
                                                      to={`/association/event/${props.orgSlug}/${item.Slug}`}>
                                                    Read More
                                                </Link>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            )
                        }) : (
                            <div className='text-center p-5'>
                                <h2 className='p-1'>
                                    <Info size='19' className='me-25'/> No Results Found
                                </h2>
                            </div>
                        )

                        }
                    </div>
                </div>
                {/* Pagination component */}
                <ul className='pagination  d-flex justify-content-center mt-3'>
                    {/* Previous button */}
                    <li className={`${currentPage === 1 ? 'disabled' : ""}`} >
                        <Link  className='page-link'  onClick={() => handlePageClick(currentPage - 1)}><FiChevronsLeft /></Link>
                    </li>

                    {/* Page numbers */}
                    {Array.from({length: Math.ceil(eventList.length / usersPerPage)}).map((_, index) => (
                        currentPage === index + 1 ?
                            <li key={index}>
                                <span className='page-link current' onClick={() => handlePageClick(index + 1)}>{index + 1}</span>
                            </li>
                            :
                            <li key={index}>
                                <Link className='page-link' onClick={() => handlePageClick(index + 1)}>{index + 1}</Link>
                            </li>
                    ))}

                    {/* Next button */}
                    <li disabled={currentPage === Math.ceil(eventList.length / usersPerPage)}>
                        <Link className='page-link'  onClick={() => handlePageClick(currentPage + 1)}><FiChevronsRight /></Link>
                    </li>
                </ul>
            </div>
        </section>

    )
}


export default EventView;
