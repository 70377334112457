import React, {useEffect, useState} from 'react'
import Navbar from '../../common/Navbar'
import Breadcrumb from '../../common/Breadcrumb'
import Footer from '../../other/Footer'
import {Link,  useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import { formatDate,  serverLink} from "../../../resources/constants";
import img from "../../../assets/images/img4.jpg";
import {
    FiEye,
} from "react-icons/fi";
import {FaEye, FaFacebookF, FaGooglePlusG, FaInstagram, FaTwitter} from "react-icons/fa";
import placeholder from "../../../assets/images/organization.jpg";
import OrganisationNewsSidebar from "./organisation-news-sidebar";
import SpinnerLoader from "../../common/spinner-loader/spinner-loader";

export default function NewsDetails() {
    const navigate = useNavigate();
    const {slug} = useParams();
    let orgSlug = '';
    let url = window.location.href;
    let split = url.split('/');
    if (split.length > 0) {
        let lastIndex = split[split.length -2]
        orgSlug = lastIndex
    }

    const [isLoading, setIsLoading] = useState(true);
    const [newsDetails, setNewsDetails] = useState({});
    const [imageList, setImagesList] = useState([]);
    const [newsList, setNewsList] = useState([]);


    const getData = async () => {
        let new_id = slug;
        await axios.get(`${serverLink}organisation/website/news-details/${new_id}`)
            .then((res) => {
                if (res.data.data.length > 0){
                    const record = res.data.data;
                    let details = record.filter(e=>e.Slug.toString()  === new_id.toString())
                    let other_news = record.filter(e=>e.Slug.toString()  !== new_id.toString())

                    const images = res.data.images;
                    setNewsDetails(details[0]);
                    setNewsList(other_news);
                    setImagesList(images);
                    updateViewCount(details[0].NewsID)
                }else{
                    navigate(`/associations`)
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const updateViewCount = async (id) => {
        const sendData = {NewsID: id};
        await axios.patch(`${serverLink}organisation/website/news/update/view-count`, sendData)
            .then((result) => {}).catch((err) => {console.log("NETWORK ERROR");});
    };


    useEffect(() => {
        if (!slug){
            navigate("/associations")
        }
        getData()
    }, [window.location.href]);
    return (isLoading ? <SpinnerLoader/> :
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="News Details" />

            {/* News Details */}
            <div className="container">
                <section className="blog-details-wrapper">
                    <div className="container">
                        <div className="blog-details-grid d-grid">
                            <div className="blog-details-left">
                                <div className="blog-details-img-box">
                                    <img  className="img-thumbnail" src={!newsDetails.ImagePath ? placeholder : `${serverLink}public/upload/news/${newsDetails.ImagePath}`} alt="Blog Details" />
                                </div>
                                <div className="blog-details-contents">
                                    <span className="blog-details-meta d-flex">{formatDate(newsDetails.InsertedDate)} - Posted by - <b>{newsDetails.InsertedName} </b>  </span>

                                    <h2>{newsDetails?.Title}.</h2>
                                    <span style={{textAlign: 'justify'}}><p  dangerouslySetInnerHTML={{__html: newsDetails?.Description}}/></span>
                                    {
                                        imageList.length > 0 ? <>
                                            <h4 className="" style={{marginTop: '20px'}}>News Images</h4>
                                            <hr/>
                                            <div className="row">
                                                {
                                                    imageList.map((item, index)=>{
                                                        return (
                                                            <div key={index} className="col-md-3 mb-3">
                                                                <img  className="img-thumbnail w-100" src={`${serverLink}public/upload/news/${item.ImagePath}`} alt="image" style={{
                                                                    maxHeight: '200px',
                                                                    minHeight: '200px',
                                                                    borderTopLeftRadius: '8px',
                                                                    borderTopRightRadius: '8px',
                                                                }}  />
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </> : <></>
                                    }

                                    <div className="blog-details-tags-grid d-grid">
                                        <div className="blog-details-tags-left">
                                         <FaEye/> {newsDetails.ViewCount}
                                        </div>
                                        <div className="blog-details-tags-right d-flex">
                                            <span>Share to: </span>
                                            <ul className="blog-share-links d-flex">
                                                <li><a href="https://facebook.com"><FaFacebookF /></a></li>
                                                <li><a href="https://twitter.com"><FaTwitter /></a></li>
                                                <li><a href="https://instagram.com"><FaInstagram /></a></li>
                                                <li><a href="https://plus.google.com"><FaGooglePlusG /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-details-right">
                                <OrganisationNewsSidebar newsData={newsList} orgSlug={orgSlug} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Footer */}
            <Footer />
        </>
    )
}
