import React from 'react'
import {Link} from "react-router-dom";
import placeholder from "../../../assets/images/organization.jpg";
import {encryptData, formatDateAndTime, serverLink} from "../../../resources/constants";

export default function OrganisationNewsSidebar(props)  {
    const blogs = props.newsData.length > 0 && props.newsData.map((blog, index) => {
        return (
            <li key={index}  className="d-flex">
                <Link to={`/association/news/${props.orgSlug}/${blog.Slug}`}>
                    <img src={!blog.ImagePath ? placeholder : `${serverLink}public/upload/news/${blog.ImagePath}`} alt="Team Details" />
                </Link>
                <div className="body">
                    <Link to={`/association/news/${props.orgSlug}/${blog.Slug}`}>
                        {blog.Title.slice(0, 55) + (blog.Title?.length > 55 ? "..." : "")}
                    </Link>
                    <span className="date">
                        {formatDateAndTime(blog.InsertedDate)}
                    </span>
                </div>
            </li>
        )
    });
        return (
            <aside className="blog-sidebar">
                <div className="blog-sidebar-widget recent-posts">
                    <h3>Recent News.</h3>
                    <ul>
                        {blogs}
                    </ul>
                </div>

            </aside>
        )
}
