import React from "react";
import slider from "../../assets/images/slider1.jpg";
import "./Hero.css";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="hero-container">
      <img src={slider} alt="Hero Background" className="hero-background" />
      <div className="hero-content">
        <h1 className="hero-title">
          Are you tired of running your association
        </h1>
        <h2 className="hero-subtitle">
          We can make it <span style={{ color: "#f66b5d" }}>better!</span>
        </h2>
        <Link to="/register" className="hero-button">
          Get started
        </Link>
      </div>
    </div>
  );
};

export default Hero;
