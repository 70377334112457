import React, {useEffect, useState} from 'react'
import Breadcrumb from '../../components/common/Breadcrumb'
import Footer from '../../components/other/Footer'
import Navbar from "../common/Navbar";
import axios from "axios";
import placeholder from "../../assets/images/organization.jpg"
import { serverLink} from "../../resources/constants";
import Divider from "../other/Divider";
import { useNavigate, useParams} from "react-router-dom";
import NewsView from "./organisation-news/organisation-news-view";
import SpinnerLoader from "../common/spinner-loader/spinner-loader";
import EventView from "./organisation-event/organisation-event-view";

function OrganizationDetails(props) {
    const navigate = useNavigate();
    const {slug} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [orgDetails, setOrganisationList] = useState({});
    const [newsList, setNewsList] = useState([]);
    const [eventList, setEventList] = useState([]);


    const getData = async () => {
        await axios.get(`${serverLink}organisation/website/details/${slug}`)
            .then((res) => {
                if (res.data.data.length > 0){
                    const record = res.data.data;
                    const news = res.data.news;
                    const events = res.data.events;
                    setOrganisationList(record[0]);
                    setNewsList(news);
                    setEventList(events);
                }else{
                    navigate("/associations")
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    useEffect(() => {
        if (!slug){
            navigate("/associations")
        }
        getData()
    }, []);


    return (
        isLoading ? <SpinnerLoader/> :
            <>
                {/* Header */}
                <header className="header-area">
                    <Navbar />
                </header>

                {/* Breadcrumb */}
                <Breadcrumb title={orgDetails?.Name} />

                {/* Organisation Details */}
                <section className="portfolio-details-wrapper">
                    <div className="container">
                        <div className="portfolio-details-image-box">
                            <div className="portfolio-details-image">
                                <img src={!orgDetails.LogoPath ? placeholder : `${serverLink}public/upload/organisation/${orgDetails.LogoPath}`} alt="Banner" style={{
                                    maxHeight: '400px',
                                    minHeight: '400px',
                                    border: '1px solid #cccccc',
                                    borderTopLeftRadius: '8px',
                                    borderTopRightRadius: '8px',
                                }}/>
                            </div>
                            <div className="portfolio-details-content">
                                    <h2 style={{marginTop: '10px'}}>{orgDetails?.Name}</h2>
                                <div className="portfolio-details-info-grid d-grid">
                                        <div className="item">
                                            <h4>Email Address</h4>
                                            <span className="d-flex">{orgDetails?.EmailAddress}</span>
                                        </div>
                                        <div className="item">
                                            <h4>Phone Number</h4>
                                            <span className="d-flex">{orgDetails?.PhoneNumber}</span>
                                        </div>
                                        <div className="item">
                                            <h4>Address</h4>
                                            <span className="d-flex">{orgDetails?.Address}</span>
                                        </div>
                                        <div className="item">
                                            <h4>Caption</h4>
                                            <span className="d-flex" dangerouslySetInnerHTML={{__html: orgDetails?.Caption}}/>
                                        </div>
                                </div>
                                <h4>About</h4>
                                    <span style={{textAlign: 'justify'}}>
                                        <p dangerouslySetInnerHTML={{__html: orgDetails?.Description}}/>
                                    </span>
                            </div>
                            <div className="related-projects-wrap">
                                    <h2>Organisation News</h2>
                            </div>
                            <NewsView data={newsList} orgSlug={slug}/>
                            <div className="related-projects-wrap">
                                <h2>Organisation Events</h2>
                            </div>
                            <EventView data={eventList} orgSlug={slug}/>
                        </div>
                    </div>
                </section>

                <Divider />

                {/* Footer */}
                <Footer />
            </>
    )
}

export default OrganizationDetails;