import React  from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import Footer from '../components/other/Footer'
import { projectAddress, projectEmail, projectPhone } from '../resources/constants';

export default function RefundPolicy() {
    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Refund Policy" />

            <section className="ctaform-wrapper" style={{marginTop: '-70px'}}>
                <div className="container">
                    <div className="ctaform-grid">
                        <div className="ctaform-left">
                            <div className="mapouter" style={{ marginTop: '20px', textAlign: 'justify' }}>
                                <h1>SECSAV DIGITAL SERVICES - Refund Policy</h1>
                                <p>This Refund Policy applies to the use of the SecSav software as a service ("SecSav")
                                    offered by SECSAV DIGITAL SERVICES, a company registered in Nigeria with the address
                                    at 42 Onitsha Crescent, Garki, Behind Gimbiya Street Abuja, Nigeria ("SECSAV DIGITAL
                                    SERVICES"). By using SecSav, you agree to abide by this Refund Policy.</p>

                                <h2>1. Refund Eligibility</h2>
                                <p>1.1. Subscription fees paid for SecSav are generally non-refundable.</p>
                                <p>1.2. SECSAV DIGITAL SERVICES may, at its sole discretion, consider refund requests in
                                    exceptional circumstances, such as technical issues or errors on SECSAV DIGITAL
                                    SERVICES' part.</p>

                                <h2>2. Refund Process</h2>
                                <p>2.1. To request a refund, you must contact SECSAV DIGITAL SERVICES at the contact
                                    information provided below.</p>
                                <p>2.2. SECSAV DIGITAL SERVICES will review your refund request and may require
                                    additional information to process the refund.</p>
                                <p>2.3. Refunds, if approved, will be processed within a reasonable timeframe.</p>

                                <h2>3. Contact Information</h2>
                                <p>If you have any questions about this Refund Policy or wish to request a refund,
                                    please contact us at:</p>
                                <p>Address: {projectAddress}</p>
                                <p>Email: {projectEmail}</p>
                                <p>Phone: {projectPhone}</p>

                                <p>This Refund Policy was last updated on May 03, 2024.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* Footer */}
            <Footer />
        </>
    )
}
