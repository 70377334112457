import React, { useState} from 'react'
import { FiChevronRight } from 'react-icons/fi'
import {Link} from 'react-router-dom'
import {encryptData, projectOrganisationLogin, randomToken, serverLink} from "../../resources/constants";
import {toast} from "react-toastify";
import axios from "axios";

export default function SignUpForm() {
    const [isLoading, setIsLoading] = useState(true);
    const initialize = {first_name:'', surname:'', phone_number:'', email_address:'', cpassword: '', password: '',  status:'active', isVerify: false, showVerify: false, code: '', userCode: ''}
    const [formData, setFormData] = useState(initialize);
    const [verification, setVerification] = useState({})

    const handleSubmit = async (canSubmit) => {
        if (formData.first_name.toString().trim() === "") { toast.error("Please enter first name"); return false; }
        if (formData.surname.toString().trim() === "") { toast.error("Please enter surname"); return false; }
        if (formData.phone_number.toString().trim() === "") { toast.error("Please enter phone number"); return false; }
        if (formData.password.toString().trim() === "") { toast.error("Please enter password"); return false; }
        if (formData.cpassword.toString().trim() === "") { toast.error("Please enter confirm password"); return false; }
        if (formData.password.toString().trim() !== formData.cpassword.toString().trim()) { toast.error("Password Mismatch"); return false; }

        if (canSubmit) {
            const sendData = {
                membership: {...formData, password: encryptData(formData.password)},
            }
            toast.info("Submitting...");
            await axios.post(`${serverLink}membership/website/registration`, sendData).then((result) => {
                if (result.data.message === "success") {
                    toast.success("Account Created Successfully");
                    setFormData(initialize)
                } else if (result.data.message === "exist") {
                    toast.error("Account already exist");
                } else {
                    toast.error(result.data.message);
                }
            })
                .catch((error) => {
                    toast.error("Please check your connection and try again!");
                });
        }else{
            const verification_code = randomToken().toUpperCase();
            const sendData = {
                FirstName: formData.first_name,
                EmailAddress: formData.email_address,
                PhoneNumber: formData.phone_number,
                Code: verification_code
            }
            await axios.post(`${serverLink}membership/registration/verification`, sendData)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("A registration verification code was sent to your email address");
                        setFormData({...formData, showVerify: true, code: verification_code, userCode: ''})
                    } else {
                        toast.error("Something went wrong sending email");
                    }
                }).catch(err => {
                    console.log(err)
                    toast.error("NETWORK ERROR");
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const handleVerification = () => {
        if (formData.userCode.toString().trim() === "") { toast.error("Please enter verification code"); return false; }
        if (formData.userCode.toUpperCase() === formData.code) {
            setFormData({...formData, isVerify: true})
            handleSubmit(true);
        } else {
            toast.error("Invalid verification code");
        }
    }

    return (
        <section className="signup-form-wrapper">
            <div className="container">
                {
                    !formData.showVerify ?
                        <div className="signup-form-box">
                            <div className="signup-form-top text-center">
                                <h1 className="signup-form-title">Create an account!</h1>
                            </div>
                            <form className="signup-form">
                                <label className='form-label' htmlFor='name'>First Name <span className="text-danger">*</span></label>
                                <input id="first_name" value={formData.first_name} onChange={(e)=>setFormData({...formData, [e.target.id]: e.target.value})} className="form-control" type="text" placeholder="First Name" />
                                <label className='form-label' htmlFor='surname'>Surname <span className="text-danger">*</span></label>
                                <input  id="surname" value={formData.surname} onChange={(e)=>setFormData({...formData, [e.target.id]: e.target.value})}  className="form-control" type="text" placeholder="Last Name" />
                                <div className="row  d-flex" style={{justifyContent: 'space-between'}}>
                                    <div className="col-md-12 form-group">
                                        <label className='form-label' htmlFor='email'>Email Address</label>
                                        <input id="email_address" value={formData.email_address} onChange={(e)=>setFormData({...formData, [e.target.id]: e.target.value})}  className="form-control" type="email" placeholder="Email Address" />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label className='form-label' htmlFor='phone'>Phone Number <span className="text-danger">*</span></label>
                                        <input id="phone_number" value={formData.phone_number} onChange={(e)=>setFormData({...formData, [e.target.id]: e.target.value})}  className="form-control" type="phone_number" placeholder="Phone Number" />
                                    </div>
                                </div>

                                <div className="row d-flex" style={{justifyContent: 'space-between', marginTop: '20px'}}>
                                    <div className="col-6 form-group">
                                        <label className='form-label' htmlFor='password'>Password <span className="text-danger">*</span></label>
                                        <input id="password" value={formData.password} onChange={(e)=>setFormData({...formData, [e.target.id]: e.target.value})}  className="form-control" type="password" placeholder="Password" />
                                    </div>
                                    <div className="col-6">
                                        <label className='form-label' htmlFor='cpassword'>Confirm Password <span className="text-danger">*</span></label>
                                        <input id="cpassword" value={formData.cpassword} onChange={(e)=>setFormData({...formData, [e.target.id]: e.target.value})}  className="form-control" type="password" placeholder="Confirm Password" />
                                    </div>
                                </div>


                                <button type="button" value="Register Account" onClick={()=>handleSubmit(false)} className="theme-button" style={{marginTop: '25px'}}>Register Account <FiChevronRight className="icon" /></button>
                            </form>
                            <p className="already-account">
                                Already have an account? <Link to={projectOrganisationLogin}>Login</Link>
                            </p>
                        </div>
                        :
                        <div className="signup-form-box">
                            <div className="signup-form-top text-center">
                                <h3 className="signup-form-title"> Enter your verification code</h3>
                            </div>
                            <form className="signup-form">
                                <input id="userCode" value={formData.userCode} onChange={(e)=>setFormData({...formData, [e.target.id]: e.target.value})} className="form-control" type="text" placeholder="Enter Your Verification Code" />

                                <button type="button" value="Verify" onClick={handleVerification} className="theme-button" style={{marginTop: '25px'}}>Verify <FiChevronRight className="icon" /></button>
                            </form>
                            <p className="already-account">
                                Didn't receive the code? <button type="button"  onClick={()=>handleSubmit(false)}>send again</button>
                            </p>
                        </div>

                }
            </div>
        </section>
    )
}
